// utils/API.js

import axios from "axios";
import PARAMS from "utils/PARAMS";
import { showSnack } from "utils/helperFunctions";

axios.defaults.baseURL = 'https://api.sanicorcatalogo.aicor.es/api/';
axios.defaults.timeout = 30000;
axios.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("apiToken");
axios.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if (401 === error.response.status) {
      if (localStorage.getItem("apiToken")) {
        showSnack('warning', 'Sesión caducada');
        axios.get('logout');
        localStorage.removeItem('apiToken');
        window.location.replace(PARAMS.baseUrl);
      }else{
        showSnack('warning', 'Usuario no autorizado');
      }
    } else {
      return Promise.reject(error);
    }
});

export default axios;
