import React, {useEffect, useState} from 'react';
import Dropzone from 'react-dropzone-uploader'
import 'react-dropzone-uploader/dist/styles.css'
import PARAMS from "utils/PARAMS";


export default function DropZone(props) {
  const {onLoadImage} = props;

  const handleChangeStatus = ({ meta }, status, files) => {
    if (status == 'done') {
      onLoadImage(files);
      console.log(files);
    }
  }

  const [imagenes, setImagenes] = React.useState([]);

  return(
    <Dropzone
      onChangeStatus={handleChangeStatus}
      styles={{ dropzone: { minHeight: 200, maxHeight: 250 } }}
      submitButtonDisabled={true}
      inputWithFilesContent="Añadir imágenes"
      inputContent="Arrastre y suelte algunos archivos aquí, o haga clic para seleccionar archivos"
    />
  )
}
