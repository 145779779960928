import React, { useState, useEffect } from "react";

// components
import Table from "components/Table/TableUi";
import Modal from "components/Modal/Modal";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import API from "utils/API";
import PARAMS from "utils/PARAMS";
import { validateFields, showSnack } from 'utils/helperFunctions';
import Moment from 'moment';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Loader from 'react-loader-spinner';
import Actions from "components/Actions/Actions";

// Core
import { makeStyles } from "@material-ui/core/styles";
import TextField from '@material-ui/core/TextField';
import Fab from '@material-ui/core/Fab';

// Icons
import AddIcon from '@material-ui/icons/Add';
import EditIcon from "@material-ui/icons/Edit";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import SearchIcon from '@material-ui/icons/Search';

export default function TableList() {
  const [actividades, setActividades] = useState([]);
  const [tableHead, setTableHead] = useState([
    {id: "prescriptor", numeric: false, disablePadding: true, label: 'Prescriptor'},
    {id: "producto", numeric: false, disablePadding: true, label: 'Producto'},
    {id: "fecha", numeric: false, disablePadding: true, label: 'Fecha'},
    {id: "acciones", numeric: false, disablePadding: true, label: 'Acciones'},
  ]);

  function createData(prescriptor, producto, fecha, acciones) {
    return { prescriptor, producto, fecha, acciones };
  }

  const [isLoad, setIsLoad] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [isProcessing, setIsProcessing] = React.useState(false);
  const [toSeach, setToSearch] = React.useState(null);
  const [actividad_id, setActividadId] = React.useState('');
  const [actividad_prescriptor, setActividadPrescriptor] = React.useState('');
  const [actividad_producto, setActividadProducto] = React.useState('');
  const [actividad_fecha, setActividadFecha] = React.useState('');

  const [openConfirm, setOpenConfirm] = React.useState(false);

  const handleClickOpenConfirm = (id) => {
    setOpenConfirm(true);
    setActividadId(id);
  };
  const handleClose = () => {
    setOpenConfirm(false);
  };

  const styles = {
    cardCategoryWhite: {
      "&,& a,& a:hover,& a:focus": {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
      },
      "& a,& a:hover,& a:focus": {
        color: "#FFFFFF"
      }
    },
    cardTitleWhite: {
      color: "#FFFFFF",
      marginTop: "0px",
      minHeight: "auto",
      fontWeight: "300",
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      marginBottom: "3px",
      textDecoration: "none",
      "& small": {
        color: "#777",
        fontSize: "65%",
        fontWeight: "400",
        lineHeight: "1"
      }
    },
    root: {
      width: '100%',
    },
  };
  const useStyles = makeStyles(styles);
  Moment.locale('es');

  useEffect(() => {
    getActividades();
  }, []);

  async function getActividades() {
    setIsLoad(false);
    await API.post("actividadLog/index", {
      findBy: toSeach
    })
    .then(res => {
      if (res.data.length > 0) {
        const arrayActividades = [];
        res.data.forEach(comercial => {
          let provincia = comercial["prescriptor"]["provincia"];
          let aux = createData(
            comercial["prescriptor"]["nombre"] + (provincia ? ' ('+provincia+') ' : ''),
            comercial['producto']['ref'],
            Moment(comercial["fecha"]).format('DD-MM-YYYY hh:mm:ss'),
            (<div className={classes.root}>
              <Actions
                del={true}
                onDelete={() => handleClickOpenConfirm(comercial['id'])}
              />
            </div>)
          );

          arrayActividades.push(aux);
        });
        setActividades(arrayActividades);
        setIsLoad(true);
      }
    })
    .catch(err => {
      console.log(err);
      showSnack('warning', 'Se ha producido un error en la carga de comerciales')
    });
  }

  async function deleteActividad(){
    if (!isProcessing) {
      setIsProcessing(true);
      await API.post(`actividadLog/destroy/${actividad_id}`)
      .then(res => {
        if (res.data) {
          getActividades();
          showSnack('success', 'Actividad eliminada correctamente');
          setIsProcessing(false);
        }
      })
      .catch(err => {
        console.log(err);
        setIsProcessing(false);
        showSnack('warning', 'Se ha producido un error, eliminando la actividad')
      });
    }
  };

  const classes = useStyles();

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="info">
            <h4 className={classes.cardTitleWhite}>Log de Actividad</h4>
            <p className={classes.cardCategoryWhite}>
              Actividad de Prescriptores
            </p>
          </CardHeader>
          <CardBody>
            <GridContainer direction="row"  alignItems="flex-end">
              <GridItem xs={3} sm={3} md={3} >
                <TextField
                  label="Buscar"
                  defaultValue={toSeach}
                  onChange={event => {
                    const { value } = event.target;
                    setToSearch(value);
                  }}
                  onKeyPress={(event) => {
                    if (event.key === 'Enter') {
                      event.preventDefault();
                      const { value } = event.target;
                      setToSearch(value);
                      getActividades()
                    }
                  }}
                />
                <Fab style={{backgroundColor: PARAMS.firstColor, color: '#fff'}} variant="extended" onClick={() => getActividades()}>
                  <SearchIcon />
                </Fab>
              </GridItem>
            </GridContainer>
            <div className={classes.root}>
              {(isLoad ?
                <>
                  <Table
                    tablePaginate={true}
                    tableHead={tableHead}
                    tableData={actividades}
                  />
                </>
              :
                <><GridContainer style={{width: '100%', height: '300px'}} direction="row"  alignItems="center" justify="center">
                    <Loader type={PARAMS.loaderType} color={PARAMS.firstColor} timeout={5000} height={100} width={100} />
                  </GridContainer>
                </>
              )}
            </div>
          </CardBody>
        </Card>
      </GridItem>
      <div>
        <Modal
          open={openConfirm}
          onCancel={() => handleClose()}
          onConfirm={() => deleteActividad()}
          confirmText="Confirmar"
          confirmIcon={<CheckCircleOutlineIcon style={{marginRight: '10px', color: '#fff'}} />} title="¿Seguro que deseas borrar el usuario?"  />
      </div>
    </GridContainer>
  );
}
