import React, { useState, useEffect } from "react";

// components
import Table from "components/Table/TableUi";
import Modal from "components/Modal/Modal";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import API from "utils/API";
import PARAMS from "utils/PARAMS";
import { validateFields, showSnack } from "utils/helperFunctions";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import Actions from "components/Actions/Actions";
import Select2 from "react-select";

// Core
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Fab from "@material-ui/core/Fab";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

// Icons
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import SearchIcon from "@material-ui/icons/Search";

export default function TableList() {
  const [prescriptores, setPrescriptores] = useState([]);
  const [tableHead, setTableHead] = useState([
    { id: "nombre", numeric: false, disablePadding: true, label: "Nombre" },
    { id: "email", numeric: false, disablePadding: true, label: "Email" },
    {
      id: "provincia",
      numeric: false,
      disablePadding: true,
      label: "Provincia",
    },
    {
      id: "especialidad",
      numeric: false,
      disablePadding: true,
      label: "Especialidad",
    },
    { id: "activo", numeric: false, disablePadding: true, label: "Activo" },
    { id: "acciones", numeric: false, disablePadding: true, label: "Acciones" },
  ]);

  function createData(
    nombre,
    email,
    provincia,
    especialidad,
    activo,
    acciones
  ) {
    return { nombre, email, provincia, especialidad, activo, acciones };
  }

  const [isLoad, setIsLoad] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [isProcessing, setIsProcessing] = React.useState(false);
  const [toSeach, setToSearch] = React.useState(null);
  const [prescriptor_id, setPrescriptorId] = React.useState("");
  const [prescriptor_name, setPrescriptorName] = React.useState("");
  const [prescriptor_email, setPrescriptorEmail] = React.useState("");
  const [prescriptor_pass, setPrescriptorPass] = React.useState("");
  const [prescriptor_pass_confirm, setPrescriptorPassConfirm] =
    React.useState("");
  const [prescriptor_telefono_fijo, setPrescriptorTelefonoFijo] =
    React.useState("");
  const [prescriptor_telefono_movil, setPrescriptorTelefonoMovil] =
    React.useState("");
  const [prescriptor_activo, setPrescriptorActivo] = React.useState(false);

  const [prescriptorProvincia, setPrescriptorProvincia] = React.useState([]);
  const [listProvinciasOptions, setListProvinciaOptions] = React.useState([]);
  /* Handlechangeprovincia */
  const handlePrescriptorProvincia = (selectedOption) => {
    let filter = [];

    listCentrosOptions.forEach((item, i) => {
      if (item.filter == selectedOption.label) {
        filter.push(item);
      }
    });
    setListCentrosFilterOptions(filter);
    setPrescriptorProvincia(selectedOption);
    setPrescriptorCentro(filter[0]);
  };
  /* loadCentrostoEdit */
  const loadCentrostoEdit = (selectedOption) => {
    let filter = [];

    listCentrosOptions.forEach((item, i) => {
      if (item.filter == selectedOption.label) {
        filter.push(item);
      }
    });
    setListCentrosFilterOptions(filter);
  };

  const [prescriptorCentro, setPrescriptorCentro] = React.useState([]);
  const [listCentrosOptions, setListCentrosOptions] = React.useState([]);
  const [listCentrosFilterOptions, setListCentrosFilterOptions] =
    React.useState([]);
  const handlePrescriptorCentro = (selectedOption) => {
    setPrescriptorCentro(selectedOption);
  };
  const [prescriptorEspecialidad, setPrescriptorEspecialidad] = React.useState(
    []
  );
  const [listEspecialidadesOptions, setListEspecialidadesOptions] =
    React.useState([]);
  const handlePrescriptorEspecialidad = (selectedOption) => {
    setPrescriptorEspecialidad(selectedOption);
  };
  const [prescriptorComercial, setPrescriptorComercial] = React.useState([]);
  const [listComercialesOptions, setListComercialesOptions] = React.useState(
    []
  );
  const handlePrescriptorComercial = (selectedOption) => {
    setPrescriptorComercial(selectedOption);
  };

  const [open, setOpen] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [openConfirm, setOpenConfirm] = React.useState(false);
  const handleClickOpen = () => {
    clearForm();
    setOpen(true);
  };
  const handleClickOpenEdit = () => {
    setOpenEdit(true);
  };
  const handleClickOpenConfirm = (id) => {
    setOpenConfirm(true);
    setPrescriptorId(id);
  };
  const handleClose = () => {
    setOpen(false);
    setOpenEdit(false);
    setOpenConfirm(false);
  };

  const handleCheckActivo = () => {
    setPrescriptorActivo(!prescriptor_activo);
  };

  const styles = {
    cardCategoryWhite: {
      "&,& a,& a:hover,& a:focus": {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0",
      },
      "& a,& a:hover,& a:focus": {
        color: "#FFFFFF",
      },
    },
    cardTitleWhite: {
      color: "#FFFFFF",
      marginTop: "0px",
      minHeight: "auto",
      fontWeight: "300",
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      marginBottom: "3px",
      textDecoration: "none",
      "& small": {
        color: "#777",
        fontSize: "65%",
        fontWeight: "400",
        lineHeight: "1",
      },
    },
    root: {
      width: "100%",
    },
  };
  const useStyles = makeStyles(styles);

  useEffect(() => {
    getPrescriptores();
  }, []);

  useEffect(() => {
    if (prescriptorProvincia.hasOwnProperty("label")) {
      console.log("Dentro");
      loadCentrostoEdit(prescriptorProvincia);
    }
  }, [prescriptorProvincia]);

  async function getPrescriptores() {
    setIsLoad(false);
    await API.post("prescriptores/index", {
      findBy: toSeach,
    })
      .then((res) => {
        if (res.data) {
          /* console.log(res.data["listaProvincias"]); */
          setListProvinciaOptions(res.data["listaProvincias"]);
          setListCentrosOptions(res.data["listaCentros"]);
          setListCentrosFilterOptions(res.data["listaCentros"]);
          setListEspecialidadesOptions(res.data["listaEspecialidades"]);
          setListComercialesOptions(res.data["listaComerciales"]);
          const arrayPresciptores = [];
          res.data["prescriptores"].forEach((prescriptor) => {
            let centro = prescriptor["centro"];
            let especialidad = prescriptor["especialidad"];
            let comercial = prescriptor["comercial"];
            let provincia = prescriptor["provincia_data"];
            let aux = createData(
              prescriptor["nombre"],
              prescriptor["user"]["email"],
              prescriptor["provincia"],
              especialidad ? especialidad["nombre"] : "",
              prescriptor["user"]["activo"] ? "Si" : "No",
              <div className={classes.root}>
                <Actions
                  edit={true}
                  onEdit={() =>
                    loadEdit(
                      prescriptor["id"],
                      prescriptor["nombre"],
                      prescriptor["user"]["email"],
                      centro
                        ? { value: centro["id"], label: centro["nombre"] }
                        : [],
                      prescriptor["telefono_fijo"],
                      prescriptor["telefono_movil"],
                      especialidad
                        ? {
                            value: especialidad["id"],
                            label: especialidad["nombre"],
                          }
                        : [],
                      provincia
                        ? { value: provincia["id"], label: provincia["nombre"] }
                        : [],
                      prescriptor["user"]["activo"],
                      comercial
                        ? {
                            value: comercial["id"],
                            label:
                              comercial["nombre"] +
                              " - " +
                              prescriptor["comercial"]["email"],
                          }
                        : []
                    )
                  }
                  del={true}
                  onDelete={() => handleClickOpenConfirm(prescriptor["id"])}
                />
              </div>
            );

            arrayPresciptores.push(aux);
          });
          setPrescriptores(arrayPresciptores);
          setIsLoad(true);
        }
      })
      .catch((err) => {
        /* console.log(err); */
        showSnack(
          "warning",
          "Se ha producido un error en la carga de prescriptores"
        );
      });
  }

  function formPrescriptores() {
    return (
      <>
        <GridItem xs={6} sm={6} md={6}>
          <TextField
            label="Nombre"
            defaultValue={prescriptor_name}
            onChange={(event) => {
              const { value } = event.target;
              setPrescriptorName(value);
            }}
            error={error && prescriptor_name == "" ? true : false}
          />
        </GridItem>
        <GridItem xs={6} sm={6} md={6}>
          <TextField
            label="Email"
            defaultValue={prescriptor_email}
            onChange={(event) => {
              const { value } = event.target;
              setPrescriptorEmail(value);
            }}
            error={error && prescriptor_email == "" ? true : false}
          />
        </GridItem>
        <GridItem xs={6} sm={6} md={6} style={{ marginTop: "10px" }}>
          <TextField
            label="Contraseña"
            type="password"
            defaultValue={prescriptor_pass}
            onChange={(event) => {
              const { value } = event.target;
              setPrescriptorPass(value);
            }}
            error={error && prescriptor_pass == "" ? true : false}
          />
        </GridItem>
        <GridItem xs={6} sm={6} md={6} style={{ marginTop: "10px" }}>
          <TextField
            label="Confirma Contraseña"
            type="password"
            defaultValue={prescriptor_pass_confirm}
            onChange={(event) => {
              const { value } = event.target;
              setPrescriptorPassConfirm(value);
            }}
            error={error && prescriptor_pass_confirm == "" ? true : false}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={12} style={{ marginTop: "20px" }}>
          <Select2
            value={prescriptorComercial}
            onChange={handlePrescriptorComercial}
            options={listComercialesOptions}
            className={
              error && prescriptorComercial.value == null ? classes.error : ""
            }
            placeholder="Selecciona el comercial relacionado"
            styles={{
              menu: (provided, state) => ({
                ...provided,
                zIndex: 9999,
              }),
            }}
            menuPosition={"fixed"}
          />
        </GridItem>
        <GridItem xs={6} sm={6} md={6} style={{ marginTop: "10px" }}>
          <Select2
            value={prescriptorProvincia}
            onChange={handlePrescriptorProvincia}
            options={listProvinciasOptions}
            className={
              error && prescriptorProvincia.value == null ? classes.error : ""
            }
            placeholder="Selecciona una provincia"
            styles={{
              menu: (provided, state) => ({
                ...provided,
                zIndex: 9999,
              }),
            }}
            menuPosition={"fixed"}
          />
        </GridItem>
        <GridItem xs={6} sm={6} md={6} style={{ marginTop: "10px" }}>
          <Select2
            value={prescriptorCentro}
            onChange={handlePrescriptorCentro}
            options={listCentrosFilterOptions}
            className={
              error && prescriptorCentro.value == null ? classes.error : ""
            }
            placeholder="Selecciona el centro"
            styles={{
              menu: (provided, state) => ({
                ...provided,
                zIndex: 9999,
              }),
            }}
            menuPosition={"fixed"}
          />
        </GridItem>
        <GridItem xs={6} sm={6} md={6} style={{ marginTop: "10px" }}>
          <TextField
            label="Teléfono Fijo"
            inputProps={{ pattern: "[a-z]" }}
            defaultValue={prescriptor_telefono_fijo}
            onChange={(event) => {
              const { value } = event.target;
              setPrescriptorTelefonoFijo(value);
            }}
            inputProps={{ maxLength: 9 }}
          />
        </GridItem>
        <GridItem xs={6} sm={6} md={6} style={{ marginTop: "10px" }}>
          <TextField
            label="Teléfono Móvil"
            defaultValue={prescriptor_telefono_movil}
            onChange={(event) => {
              const { value } = event.target;
              setPrescriptorTelefonoMovil(value);
            }}
            inputProps={{ maxLength: 9 }}
          />
        </GridItem>
        <GridItem xs={6} sm={6} md={6} style={{ marginTop: "10px" }}>
          <Select2
            value={prescriptorEspecialidad}
            onChange={handlePrescriptorEspecialidad}
            options={listEspecialidadesOptions}
            className={
              error && prescriptorEspecialidad.value == null
                ? classes.error
                : ""
            }
            placeholder="Selecciona la especialidad"
            styles={{
              menu: (provided, state) => ({
                ...provided,
                zIndex: 9999,
              }),
            }}
            menuPosition={"fixed"}
          />
        </GridItem>
        <GridItem xs={6} sm={6} md={6} style={{ marginTop: "20px" }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={prescriptor_activo}
                onChange={() => handleCheckActivo()}
                value="checkedB"
                color="primary"
                classes={{
                  root: classes.radioCustom,
                  checked: classes.checked,
                }}
              />
            }
            label="Activo"
          />
        </GridItem>
      </>
    );
  }

  async function createPrescriptor() {
    if (!isProcessing) {
      var validate_fields = new Map([
        ["nombre", prescriptor_name],
        ["email", prescriptor_email],
        ["contraseña", prescriptor_pass],
        ["confirmar contraseña", prescriptor_pass_confirm],
      ]);
      var validate = validateFields(validate_fields);
      setError(false);
      if (validate.status) {
        setIsProcessing(true);
        if (prescriptor_pass !== prescriptor_pass_confirm) {
          setIsProcessing(false);
          showSnack("warning", "Las contraseñas deben ser iguales");
          return false;
        }
        await API.post("prescriptores/store", {
          nombre: prescriptor_name,
          email: prescriptor_email,
          password: prescriptor_pass,
          centro_id: prescriptorCentro["value"],
          telefono_fijo: prescriptor_telefono_fijo,
          telefono_movil: prescriptor_telefono_movil,
          especialidad_id: prescriptorEspecialidad["value"],
          provincia: prescriptorProvincia["label"],
          activo: prescriptor_activo,
          comercial_id: prescriptorComercial["value"],
        })
          .then((res) => {
            if (res.data) {
              handleClose();
              getPrescriptores();
              showSnack("success", "Prescriptor creado correctamente");
            }
            setIsProcessing(false);
          })
          .catch((err) => {
            if (
              err.response.data.error &&
              typeof err.response.data.error === "string"
            ) {
              showSnack("warning", err.response.data.error);
            } else {
              showSnack("warning", "Se ha producido un error");
            }
            setIsProcessing(false);
          });
      } else {
        showSnack("warning", validate.message);
        setError(true);
      }
    }
  }

  function clearForm() {
    setPrescriptorId("");
    setPrescriptorName("");
    setPrescriptorEmail("");
    setPrescriptorCentro([]);
    setPrescriptorTelefonoFijo("");
    setPrescriptorTelefonoMovil("");
    setPrescriptorEspecialidad([]);
    setPrescriptorProvincia("");
    setPrescriptorPass("");
    setPrescriptorPassConfirm("");
    setPrescriptorComercial([]);
    setPrescriptorActivo(false);
  }

  function loadEdit(
    id,
    nombre,
    email,
    clinica_ref,
    telefono_fijo,
    telefono_movil,
    especialidad,
    provincia,
    activo,
    comercial
  ) {
    setPrescriptorId(id);
    setPrescriptorName(nombre);
    setPrescriptorEmail(email);
    setPrescriptorCentro(clinica_ref);
    setPrescriptorTelefonoFijo(telefono_fijo);
    setPrescriptorTelefonoMovil(telefono_movil);
    setPrescriptorEspecialidad(especialidad);
    setPrescriptorProvincia(provincia);
    setPrescriptorActivo(activo);
    setPrescriptorComercial(comercial);
    handleClickOpenEdit();
  }

  async function editPrescriptor() {
    if (!isProcessing) {
      var validate_fields = new Map([
        ["nombre", prescriptor_name],
        ["email", prescriptor_email],
      ]);
      var validate = validateFields(validate_fields);
      setError(false);
      if (validate.status) {
        setIsProcessing(true);
        if (
          prescriptor_pass !== "" &&
          prescriptor_pass !== prescriptor_pass_confirm
        ) {
          showSnack("warning", "Las contraseñas deben ser iguales");
          setIsProcessing(false);
          return false;
        }
        await API.post(`prescriptores/update/${prescriptor_id}`, {
          nombre: prescriptor_name,
          email: prescriptor_email,
          password: prescriptor_pass,
          centro_id: prescriptorCentro["value"],
          telefono_fijo: prescriptor_telefono_fijo,
          telefono_movil: prescriptor_telefono_movil,
          especialidad_id: prescriptorEspecialidad["value"],
          provincia: prescriptorProvincia["label"],
          activo: prescriptor_activo,
          comercial_id: prescriptorComercial["value"],
        })
          .then((res) => {
            if (res.data) {
              handleClose();
              getPrescriptores();
              showSnack("success", "Prescriptor editado correctamente");
            }
            setIsProcessing(false);
          })
          .catch((err) => {
            if (
              err.response.data.error &&
              typeof err.response.data.error === "string"
            ) {
              showSnack("warning", err.response.data.error);
            } else {
              showSnack("warning", "Se ha producido un error");
            }
            setIsProcessing(false);
          });
      } else {
        showSnack("warning", validate.message);
        setError(true);
      }
    }
  }

  async function deletePrescriptor() {
    if (!isProcessing) {
      setIsProcessing(true);
      await API.post(`prescriptores/destroy/${prescriptor_id}`)
        .then((res) => {
          if (res.data) {
            handleClose();
            getPrescriptores();
            showSnack("success", "Prescriptor eliminado correctamente");
            setIsProcessing(false);
          }
        })
        .catch((err) => {
          /* console.log(err); */
          setIsProcessing(false);
          showSnack(
            "warning",
            "Se ha producido un error, eliminando al prescriptor"
          );
        });
    }
  }

  const classes = useStyles();

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="info">
            <h4 className={classes.cardTitleWhite}>Prescriptores</h4>
            <p className={classes.cardCategoryWhite}>
              Gestión de prescriptores
            </p>
          </CardHeader>
          <CardBody>
            <GridContainer direction="row" alignItems="flex-end">
              <GridItem xs={3} sm={3} md={3}>
                <Fab
                  style={{ backgroundColor: PARAMS.firstColor, color: "#fff" }}
                  variant="extended"
                  onClick={() => handleClickOpen()}
                >
                  <AddIcon />
                  Crear prescriptor
                </Fab>
              </GridItem>
              <GridItem xs={3} sm={3} md={3}>
                <TextField
                  label="Buscar"
                  defaultValue={toSeach}
                  onChange={(event) => {
                    const { value } = event.target;
                    setToSearch(value);
                  }}
                  onKeyPress={(event) => {
                    if (event.key === "Enter") {
                      event.preventDefault();
                      const { value } = event.target;
                      setToSearch(value);
                      getPrescriptores();
                    }
                  }}
                />
                <Fab
                  style={{ backgroundColor: PARAMS.firstColor, color: "#fff" }}
                  variant="extended"
                  onClick={() => getPrescriptores()}
                >
                  <SearchIcon />
                </Fab>
              </GridItem>
            </GridContainer>
            <div className={classes.root}>
              {isLoad ? (
                <>
                  <Table
                    tablePaginate={true}
                    tableHead={tableHead}
                    tableData={prescriptores}
                  />
                </>
              ) : (
                <>
                  <GridContainer
                    style={{ width: "100%", height: "300px" }}
                    direction="row"
                    alignItems="center"
                    justify="center"
                  >
                    <Loader
                      type={PARAMS.loaderType}
                      timeout={5000}
                      color={PARAMS.firstColor}
                      height={100}
                      width={100}
                    />
                  </GridContainer>
                </>
              )}
            </div>
          </CardBody>
        </Card>
      </GridItem>
      <div>
        <Modal
          open={open}
          onCancel={() => handleClose()}
          content={formPrescriptores()}
          onConfirm={() => createPrescriptor()}
          title="Crear prescriptor"
          width="md"
        />
        <Modal
          open={openEdit}
          onCancel={() => handleClose()}
          content={formPrescriptores()}
          onConfirm={() => editPrescriptor()}
          title="Editar prescriptor"
          width="md"
        />
        <Modal
          open={openConfirm}
          onCancel={() => handleClose()}
          onConfirm={() => deletePrescriptor()}
          confirmText="Confirmar"
          confirmIcon={
            <CheckCircleOutlineIcon
              style={{ marginRight: "10px", color: "#fff" }}
            />
          }
          title="¿Seguro que deseas borrar al prescriptor?"
        />
      </div>
    </GridContainer>
  );
}
