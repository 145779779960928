import React from "react";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Fab from '@material-ui/core/Fab';
import GridContainer from "components/Grid/GridContainer";
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import PARAMS from "utils/PARAMS";

export default function CustomModal(props) {

  const { open, title, content, onCancel, onConfirm, cancelIcon, confirmIcon, cancelText, confirmText, onlyConfirm, width } = props;

  return(<Dialog maxWidth={width} open={open} onClose={onCancel} aria-labelledby="form-dialog-title">
    <DialogTitle id="form-dialog-title">{title}</DialogTitle>
    <DialogContent>
      <GridContainer>
        {content}
      </GridContainer>
    </DialogContent>
    <DialogActions>
      {onlyConfirm ? <></> : <>
        <Fab style={{backgroundColor: '#555555', color: '#fff'}} variant="extended" onClick={onCancel}>
          {cancelIcon ? <>{cancelIcon}</> : <><CancelIcon style={{marginRight: '10px', color: '#fff'}} /></>}
          {cancelText ? <>{cancelText}</> : <>Cancelar</>}
        </Fab>
      </>}
      <Fab style={{backgroundColor: PARAMS.firstColor, color: '#fff'}} variant="extended" onClick={onConfirm}>
        {confirmIcon ? <>{confirmIcon}</> : <><SaveIcon style={{marginRight: '10px', color: '#fff'}} /></>}
        {confirmText ? <>{confirmText}</> : <>Guardar</>}
      </Fab>
    </DialogActions>
  </Dialog>);

}
