import React, { useState, useEffect } from "react";

// components
import Table from "components/Table/TableUi";
import Modal from "components/Modal/Modal";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import API from "utils/API";
import PARAMS from "utils/PARAMS";
import { validateFields, showSnack } from "utils/helperFunctions";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import Actions from "components/Actions/Actions";
import Select2 from "react-select";
import { saveAs } from "file-saver";
import Dropzone from "components/DropZone/DropZone";

// Core
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Fab from "@material-ui/core/Fab";
import Typography from "@material-ui/core/Typography";

// Icons
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import SearchIcon from "@material-ui/icons/Search";

export default function TableList() {
  const [familias, setFamilias] = useState([]);
  const [tableHead, setTableHead] = useState([
    { id: "nombre", numeric: false, disablePadding: true, label: "Nombre" },
    { id: "codigo", numeric: false, disablePadding: true, label: "Código" },
    { id: "nivel", numeric: false, disablePadding: true, label: "Nivel" },
    { id: "acciones", numeric: false, disablePadding: true, label: "Acciones" },
  ]);

  function createData(nombre, codigo, nivel, acciones) {
    return { nombre, codigo, nivel, acciones };
  }

  const [isLoad, setIsLoad] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [isProcessing, setIsProcessing] = React.useState(false);
  const [toSeach, setToSearch] = React.useState(null);
  const [familia_id, setFamiliaId] = React.useState("");
  const [familia_name, setFamiliaName] = React.useState("");
  const [familia_codigo, setFamiliaCodigo] = React.useState("");

  const [familia_nivel, setFamiliaNivel] = React.useState("");
  const [familiaNivelOptions, setFamiliaNivelOptions] = React.useState([
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
  ]);
  const handleFamiliaNivel = (selectedOption, action, aux) => {
    let options = sub_familia_options;
    if (aux) {
      options = aux;
    }

    setFamiliaNivel(selectedOption);
    // Cargar SubFamilias
    var filter = [];
    options.forEach((item, i) => {
      if (item.nivel == parseInt(selectedOption.value) - 1) {
        filter.push(item);
      }
    });
    setSubFamiliaFilter(filter);
  };

  const [sub_familia, setSubFamilia] = React.useState([]);
  const [sub_familia_filter, setSubFamiliaFilter] = React.useState([]);
  const [sub_familia_options, setSubFamiliaOptions] = React.useState([]);
  const handleSubFamilia = (selectedOption) => {
    setSubFamilia(selectedOption);
  };

  const [open, setOpen] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [openConfirm, setOpenConfirm] = React.useState(false);
  const [excel, setExcel] = React.useState(null);
  const handleClickOpen = () => {
    clearForm();
    setOpen(true);
  };
  const handleClickOpenEdit = () => {
    setOpenEdit(true);
  };
  const handleClickOpenConfirm = (id) => {
    setOpenConfirm(true);
    setFamiliaId(id);
  };
  const handleClose = () => {
    setOpen(false);
    setOpenEdit(false);
    setOpenConfirm(false);
  };

  const handleDonwloadExcel = () => {
    downloadExcel();
  };

  const handleDonwloadPlantilla = () => {
    donwloadPlantilla();
  };

  const handleSubmitExcel = (event) => {
    event.preventDefault();
    const fd = new FormData();
    fd.append("file", excel);
    subirExcel(fd);
  };

  const styles = {
    cardCategoryWhite: {
      "&,& a,& a:hover,& a:focus": {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0",
      },
      "& a,& a:hover,& a:focus": {
        color: "#FFFFFF",
      },
    },
    cardTitleWhite: {
      color: "#FFFFFF",
      marginTop: "0px",
      minHeight: "auto",
      fontWeight: "300",
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      marginBottom: "3px",
      textDecoration: "none",
      "& small": {
        color: "#777",
        fontSize: "65%",
        fontWeight: "400",
        lineHeight: "1",
      },
    },
    root: {
      width: "100%",
    },
  };
  const useStyles = makeStyles(styles);

  useEffect(() => {
    getFamilias();
  }, []);

  async function getFamilias() {
    setIsLoad(false);
    await API.post("familias/index", {
      findBy: toSeach,
    })
      .then((res) => {
        if (res.data) {
          const arrayFamilias = [];

          res.data["familia"].forEach((familia) => {
            var familiaRelacionadaArray = [];
            if (familia["familias_relacionadas"]) {
              familia["familias_relacionadas"].forEach((familia) => {
                familiaRelacionadaArray.push({
                  value: familia["id"],
                  label: familia["nombre"],
                });
              });
            }
            let aux = createData(
              familia["nombre"],
              familia["codigo"],
              familia["nivel"],
              <div className={classes.root}>
                <Actions
                  edit={true}
                  onEdit={() =>
                    loadEdit(
                      familia["id"],
                      familia["nombre"],
                      familia["codigo"],
                      { value: familia["nivel"], label: familia["nivel"] },
                      familiaRelacionadaArray,
                      res.data["lista"]
                    )
                  }
                  del={true}
                  onDelete={() => handleClickOpenConfirm(familia["id"])}
                />
              </div>
            );

            arrayFamilias.push(aux);
          });

          setSubFamiliaOptions(res.data["lista"]);
          setFamilias(arrayFamilias);
          setIsLoad(true);
        }
      })
      .catch((err) => {
        console.log(err);
        showSnack(
          "warning",
          "Se ha producido un error en la carga de familias"
        );
      });
  }

  function formFamilias() {
    return (
      <>
        <GridItem xs={8} sm={8} md={8}>
          <TextField
            label="Nombre"
            defaultValue={familia_name}
            onChange={(event) => {
              const { value } = event.target;
              setFamiliaName(value);
            }}
            fullWidth={true}
            error={error && familia_name == "" ? true : false}
          />
        </GridItem>
        <GridItem xs={4} sm={4} md={4} style={{ marginTop: "10px" }}>
          <Select2
            value={familia_nivel}
            onChange={handleFamiliaNivel}
            options={familiaNivelOptions}
            className={
              error && familia_nivel.value == null ? classes.error : ""
            }
            placeholder="Nivel"
            styles={{
              menu: (provided, state) => ({
                ...provided,
                zIndex: 9999,
              }),
            }}
            menuPosition={"fixed"}
          />
        </GridItem>
        <GridItem xs={4} sm={4} md={4} style={{ marginTop: "10px" }}>
          <TextField
            label="Código"
            defaultValue={familia_codigo}
            onChange={(event) => {
              const { value } = event.target;
              setFamiliaCodigo(value);
            }}
            fullWidth={true}
            error={error && familia_name == "" ? true : false}
          />
        </GridItem>

        <GridItem
          xs={8}
          sm={8}
          md={8}
          style={{ marginTop: "20px", paddingBottom: "80px" }}
        >
          <Select2
            isMulti
            value={sub_familia}
            onChange={handleSubFamilia}
            options={sub_familia_filter}
            className={error && sub_familia.value == null ? classes.error : ""}
            placeholder="Selecciona sub familia"
            styles={{
              menu: (provided, state) => ({
                ...provided,
                zIndex: 9999,
              }),
            }}
            menuPosition={"fixed"}
          />
        </GridItem>
      </>
    );
  }

  async function createFamilia() {
    if (!isProcessing) {
      var validate_fields = new Map([
        ["nombre", familia_name],
        ["nivel", familia_nivel],
      ]);
      var validate = validateFields(validate_fields);
      setError(false);
      if (validate.status) {
        setIsProcessing(true);
        await API.post("familias/store", {
          nombre: familia_name,
          codigo: familia_codigo,
          relacionados: sub_familia,
          nivel: familia_nivel["value"],
        })
          .then((res) => {
            if (res.data) {
              handleClose();
              getFamilias();
              showSnack("success", "Familia de producto creada correctamente");
            }
            setIsProcessing(false);
          })
          .catch((err) => {
            if (
              err.response.data.error &&
              typeof err.response.data.error === "string"
            ) {
              showSnack("warning", err.response.data.error);
            } else {
              showSnack("warning", "Se ha producido un error");
            }
            setIsProcessing(false);
          });
      } else {
        showSnack("warning", validate.message);
        setError(true);
      }
    }
  }

  function clearForm() {
    setFamiliaId("");
    setFamiliaName("");
    setFamiliaNivel("");
    setFamiliaCodigo("");
    setSubFamilia([]);
  }

  function loadEdit(id, nombre, codigo, nivel, relacionado, listaFamilias) {
    setFamiliaId(id);
    setFamiliaName(nombre);
    setFamiliaCodigo(codigo);

    handleFamiliaNivel(nivel, null, listaFamilias);
    setSubFamilia(relacionado);
    handleClickOpenEdit();
  }

  async function editFamilia() {
    if (!isProcessing) {
      var validate_fields = new Map([
        ["nombre", familia_name],
        ["nivel", familia_nivel],
      ]);
      var validate = validateFields(validate_fields);
      setError(false);
      if (validate.status) {
        setIsProcessing(true);
        await API.post(`familias/update/${familia_id}`, {
          nombre: familia_name,
          codigo: familia_codigo,
          relacionados: sub_familia,
          nivel: familia_nivel["value"],
        })
          .then((res) => {
            if (res.data) {
              handleClose();
              getFamilias();
              showSnack("success", "Familia de producto editada correctamente");
            }
            setIsProcessing(false);
          })
          .catch((err) => {
            if (
              err.response.data.error &&
              typeof err.response.data.error === "string"
            ) {
              showSnack("warning", err.response.data.error);
            } else {
              showSnack("warning", "Se ha producido un error");
            }
            setIsProcessing(false);
          });
      } else {
        showSnack("warning", validate.message);
        setError(true);
      }
    }
  }

  async function deleteFamilia() {
    if (!isProcessing) {
      setIsProcessing(true);
      await API.post(`familias/destroy/${familia_id}`)
        .then((res) => {
          if (res.data) {
            handleClose();
            getFamilias();
            showSnack("success", "Familia de producto eliminada correctamente");
            setIsProcessing(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setIsProcessing(false);
          showSnack(
            "warning",
            "Se ha producido un error, eliminando la familia de productos"
          );
        });
    }
  }

  async function downloadExcel() {
    await API.get("familias/export", { responseType: "blob" })
      .then((res) => {
        if (res.data) {
          let blob = new Blob([res.data], { type: "application/vnd.ms-excel" });
          saveAs(blob, "Familias.xlsx");
          showSnack("success", "Descargado Excel Familias");
        }
      })
      .catch((err) => {
        console.log(err);
        showSnack("warning", "Fallo al descargar el excel");
      });
  }

  async function subirExcel(fd) {
    setIsLoad(false);

    await API.post(`familias/import`, fd, { responseType: "blob" })
      .then((res) => {
        setIsLoad(true);
        if (res.data) {
          let blob = new Blob([res.data], { type: "application/vnd.ms-excel" });
          saveAs(blob, "LogsFamilias.xlsx");
          showSnack("success", "Familias de importadas correctamente");
        }
      })
      .catch((err) => {
        setIsLoad(true);
        console.log(err);
        showSnack("warning", "Error, Al importar Familias");
      });
  }

  async function donwloadPlantilla() {
    await API.get("familias/plantilla", { responseType: "blob" })
      .then((res) => {
        if (res.data) {
          let blob = new Blob([res.data], {
            type: "application/vnd.ms-excel",
          });
          saveAs(blob, "plantillaFamilias.xlsx");
          showSnack("success", "Plantilla descargada");
        }
      })
      .catch((err) => {
        console.log(err);
        showSnack("warning", "Fallo al descargar la plantilla");
      });
  }

  const classes = useStyles();

  return (
    <GridContainer>
      {/* IMPORTACION DE FAMILIAS */}
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          {/* CABEZERA */}
          <CardHeader color="info">
            <h4 className={classes.cardTitleWhite}>Familia de Producto</h4>
            <p className={classes.cardCategoryWhite}>
              Gestión de familias de productos
            </p>
          </CardHeader>
          <CardBody>
            {/* CONTROLADORES */}
            <GridContainer direction="row" alignItems="flex-end">
              <GridItem xs={12} sm={1} md={1}>
                <Fab
                  style={{ backgroundColor: PARAMS.firstColor, color: "#fff" }}
                  variant="extended"
                  onClick={() => handleClickOpen()}
                >
                  <AddIcon />
                </Fab>
              </GridItem>
              <GridItem xs={12} sm={7} md={9}>
                <TextField
                  label="Buscar"
                  defaultValue={toSeach}
                  onChange={(event) => {
                    const { value } = event.target;
                    setToSearch(value);
                  }}
                  onKeyPress={(event) => {
                    if (event.key === "Enter") {
                      event.preventDefault();
                      const { value } = event.target;
                      setToSearch(value);
                      getFamilias();
                    }
                  }}
                />
                <Fab
                  style={{ backgroundColor: PARAMS.firstColor, color: "#fff" }}
                  variant="extended"
                  onClick={() => getFamilias()}
                >
                  <SearchIcon />
                </Fab>
              </GridItem>
              <GridItem xs={12} sm={4} md={2}>
                <Fab
                  style={{ backgroundColor: PARAMS.firstColor, color: "#fff" }}
                  variant="extended"
                  onClick={() => handleDonwloadExcel()}
                >
                  Descargar Excel
                </Fab>
              </GridItem>
            </GridContainer>
            {/* TABLA */}
            <div className={classes.root}>
              {isLoad ? (
                <>
                  <Table
                    tablePaginate={true}
                    tableHead={tableHead}
                    tableData={familias}
                  />
                </>
              ) : (
                <>
                  <GridContainer
                    style={{ width: "100%", height: "300px" }}
                    direction="row"
                    alignItems="center"
                    justify="center"
                  >
                    <Loader
                      type={PARAMS.loaderType}
                      timeout={5000}
                      color={PARAMS.firstColor}
                      height={100}
                      width={100}
                    />
                  </GridContainer>
                </>
              )}
            </div>
          </CardBody>
        </Card>
      </GridItem>
      {/* IMPORTACION MASIVA DE FAMILIAS */}
      <Card>
        <CardHeader color="info">
          <h4 className={classes.cardTitleWhite}>
            Importación de Familias de Producto
          </h4>
          <p className={classes.cardCategoryWhite}>
            Selecciona tu excel para importar las Familias de Productos
          </p>
        </CardHeader>
        <CardBody>
          <GridContainer direction="row" alignItems="flex-end">
            <GridItem xs={12} sm={6} md={8}>
              <Fab
                style={{
                  backgroundColor: PARAMS.firstColor,
                  color: "#fff",
                  marginBotton: 100,
                }}
                variant="extended"
                onClick={() => handleDonwloadPlantilla()}
              >
                Descargar Plantilla
              </Fab>
            </GridItem>
            <GridItem xs={12} sm={6} md={4}>
              <form onSubmit={handleSubmitExcel}>
                <input
                  type="file"
                  onChange={(event) => setExcel(event?.target?.files[0])}
                />
                <button type="submit">SUBIR PLANTILLA</button>
              </form>
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>

      <div>
        <Modal
          open={open}
          onCancel={() => handleClose()}
          content={formFamilias()}
          onConfirm={() => createFamilia()}
          title="Crear familia de productos"
        />
        <Modal
          open={openEdit}
          onCancel={() => handleClose()}
          content={formFamilias()}
          onConfirm={() => editFamilia()}
          title="Editar familia de productos"
        />
        <Modal
          open={openConfirm}
          onCancel={() => handleClose()}
          onConfirm={() => deleteFamilia()}
          confirmText="Confirmar"
          confirmIcon={
            <CheckCircleOutlineIcon
              style={{ marginRight: "10px", color: "#fff" }}
            />
          }
          title="¿Seguro que deseas borrar la familia de productos y todos sus hijos?"
        />
      </div>
    </GridContainer>
  );
}
