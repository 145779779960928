import React, { useState, useEffect } from "react";

// components
import Table from "components/Table/TableUi";
import Datatable from "components/Table/Datatable";
import Modal from "components/Modal/Modal";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import API from "utils/API";
import PARAMS from "utils/PARAMS";
import { validateFields, showSnack, toBase64 } from "utils/helperFunctions";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import Select2 from "react-select";
import Actions from "components/Actions/Actions";
import Dropzone from "components/DropZone/DropZone";

// Core
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Fab from "@material-ui/core/Fab";
import Typography from "@material-ui/core/Typography";
import { saveAs } from "file-saver";

// Icons
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import SearchIcon from "@material-ui/icons/Search";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";

export default function TableList() {
  const [productos, setProductos] = useState([]);
  const [totalData, setTotalData] = useState(0);
  const [perPageData, setPerPageData] = useState(10);
  const [actualPage, setActualPage] = useState(0);
  var columnNames = [
    { name: "Referencia", key: "referencia" },
    { name: "Descripción", key: "descripcion" },
    { name: "Nuevo", key: "nuevo" },
    { name: "Acciones", key: "acciones", width: "300px", sortable: false },
  ];

  function createData(referencia, descripcion, nuevo, acciones) {
    return { referencia, descripcion, nuevo, acciones };
  }

  const [isLoad, setIsLoad] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [isProcessing, setIsProcessing] = React.useState(false);
  const [producto_id, setProductoId] = React.useState("");
  const [producto_ref, setProductoRef] = React.useState("");
  const [producto_descripcion, setProductoDescripcion] = React.useState("");
  const [producto_renovacion, setProductoRenovacion] = React.useState("");
  const [producto_indicaciones, setProductoIndicaciones] = React.useState("");
  const [producto_nuevo, setProductoNuevo] = React.useState(false);
  const [producto_imagenes, setProductoImagenes] = React.useState([]);
  const [producto_imagenes_guardadas, setProductoImagenesGuardadas] =
    React.useState([]);
  const [producto_imagenes_borrar, setProductoImagenesBorrar] = React.useState(
    []
  );

  const [producto_grupo, setProductoGrupo] = React.useState("");
  const [listGrupoOptions, setListGrupoOptions] = React.useState([]);
  const handleGrupo = (selectedOption, action, aux) => {
    let options = listSubGrupoOptions;
    if (aux) {
      options = aux;
    }
    setProductoGrupo(selectedOption);
    // Reseta SubGrupo y Categoria
    setProductoSubGrupo([]);
    setProductoCategoria([]);
    // Cargar SubGrupo
    var filter = [];
    options.forEach((item, i) => {
      item.relaciones.forEach((relacion, j) => {
        if (relacion.familia_hijo_id == selectedOption.value) {
          filter.push(item);
        }
      });
    });
    setListSubGrupoFilter(filter);
  };
  const [producto_subGrupo, setProductoSubGrupo] = React.useState("");
  const [listSubGrupoOptions, setListSubGrupoOptions] = React.useState([]);
  const [listSubGrupoFilter, setListSubGrupoFilter] = React.useState([]);
  const handleSubGrupo = (selectedOption, action, aux) => {
    let options = listCategoriaOptions;
    if (aux) {
      options = aux;
    }
    setProductoSubGrupo(selectedOption);
    // Reseta Categoria
    setProductoCategoria([]);
    // Cargar Categoria
    var filter = [];
    options.forEach((item, i) => {
      item.relaciones.forEach((relacion, j) => {
        if (relacion.familia_hijo_id == selectedOption.value) {
          filter.push(item);
        }
      });
    });
    setListCategoriaFilter(filter);
  };
  const [producto_categoria, setProductoCategoria] = React.useState("");
  const [listCategoriaOptions, setListCategoriaOptions] = React.useState([]);
  const [listCategoriaFilter, setListCategoriaFilter] = React.useState([]);
  const handleCategoria = (selectedOption) => {
    setProductoCategoria(selectedOption);
  };

  function getFamiliaValue(familia) {
    var result = [];
    familia.data.forEach((item, i) => {
      if (item.value == familia.id) {
        result = item;
      }
    });

    return result;
  }

  const [productosRelacionados, setProductosRelacionados] = React.useState([]);
  const [listProductosOptions, setListProductosOptions] = React.useState([]);
  const handleProductoRelacionados = (selectedOption) => {
    setProductosRelacionados(selectedOption);
  };

  const [producto_visado, setProductoVisado] = React.useState("");
  const [productoVisadoOptions, setProductoVisadoOptions] = React.useState([
    { value: "", label: "" },
    { value: "S", label: "S" },
  ]);
  const handleProductoVisado = (selectedOption) => {
    setProductoVisado(selectedOption);
  };

  const [open, setOpen] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [openConfirm, setOpenConfirm] = React.useState(false);
  const [excel, setExcel] = React.useState(null);
  const [images, setImages] = React.useState([]);

  const handleClickOpen = () => {
    clearForm();
    setOpen(true);
  };
  const handleClickOpenEdit = () => {
    setOpenEdit(true);
  };
  const handleClickOpenConfirm = (id) => {
    setOpenConfirm(true);
    setProductoId(id);
  };
  const handleClose = () => {
    setOpen(false);
    setOpenEdit(false);
    setOpenConfirm(false);
  };

  const handleCheckNuevo = () => {
    setProductoNuevo(!producto_nuevo);
  };

  const handleDeleteImagen = (id) => {
    var toDelete = producto_imagenes_borrar;
    toDelete.push(id);
    setProductoImagenesBorrar(toDelete);
    document.getElementById("imagenCustomPreview_" + id).remove();
  };

  const handleImage = (img) => {
    var arrayImagenes = [];
    img.forEach((item, i) => {
      var previewUrl = item["meta"]["previewUrl"];
      var xhr = new XMLHttpRequest();
      xhr.open("GET", previewUrl, true);
      xhr.responseType = "blob";
      xhr.onload = function (e) {
        if (this.status == 200) {
          var myBlob = this.response;
          var reader = new FileReader();
          reader.readAsDataURL(myBlob);
          reader.onloadend = function () {
            var base64data = reader.result;
            arrayImagenes.push(base64data);
          };
        }
      };
      xhr.send();
    });
    setProductoImagenes(arrayImagenes);
  };

  const handleDonwloadExcel = () => {
    downloadExcel();
  };

  const handleDonwloadPlantilla = () => {
    donwloadPlantilla();
  };

  const handleFiles = (files) => {
    for (let i = 0; i < files.length; i++) {
      console.log(files[i]);
      toBase64(files[i]).then((file) => {
        setImages((prevState) => [
          ...prevState,
          { name: files[i].name, base64: file },
        ]);
      });
    }
  };

  const handleSubmitExcel = (event) => {
    event.preventDefault();
    const fd = new FormData();
    fd.append("file", excel);
    for (let i = 0; i < images.length; i++) {
      fd.append("images[]", JSON.stringify(images[i]));
    }
    subirExcel(fd);
  };

  const styles = {
    cardCategoryWhite: {
      "&,& a,& a:hover,& a:focus": {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0",
      },
      "& a,& a:hover,& a:focus": {
        color: "#FFFFFF",
      },
    },
    cardTitleWhite: {
      color: "#FFFFFF",
      marginTop: "0px",
      minHeight: "auto",
      fontWeight: "300",
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      marginBottom: "3px",
      textDecoration: "none",
      "& small": {
        color: "#777",
        fontSize: "65%",
        fontWeight: "400",
        lineHeight: "1",
      },
    },
    root: {
      width: "100%",
    },
    radioCustom: {
      color: PARAMS.firstColor,
      "&$checked": {
        color: PARAMS.firstColor,
      },
    },
    checked: {},
  };
  const useStyles = makeStyles(styles);

  useEffect(() => {
    getProductos();
  }, [perPageData, actualPage]);

  const handleSearch = (find) => {
    getProductos(actualPage, find);
  };

  async function getProductos(page = 0, findBy = null) {
    setIsLoad(false);
    await API.post("productos/index", { findBy, page, perPageData })
      .then((res) => {
        if (res.data) {
          const arrayProductos = [];

          setListProductosOptions(res.data["lista"]);
          setListGrupoOptions(res.data["grupos"]);
          setListSubGrupoOptions(res.data["subgrupos"]);
          setListCategoriaOptions(res.data["categorias"]);

          res.data["productos"].forEach((producto) => {
            var productoRelacionadoArray = [];
            if (producto["productos_relacionados"]) {
              producto["productos_relacionados"].forEach((producto) => {
                productoRelacionadoArray.push({
                  value: producto["id"],
                  label: producto["ref"],
                });
              });
            }
            let aux = createData(
              producto["ref"],
              producto["descripcion"].substr(0, 18) + "...",
              producto["nuevo"] ? "Si" : "No",
              <div className={classes.root}>
                <Actions
                  edit={true}
                  onEdit={() =>
                    loadEdit(
                      producto["id"],
                      producto["ref"],
                      producto["descripcion"],
                      producto["indicaciones"],
                      producto["renovacion"],
                      producto["visado"],
                      producto["nuevo"],
                      productoRelacionadoArray,
                      producto["imagenes"],
                      { id: producto["grupo_id"], data: res.data["grupos"] },
                      {
                        id: producto["subgrupo_id"],
                        data: res.data["subgrupos"],
                      },
                      {
                        id: producto["categoria_id"],
                        data: res.data["categorias"],
                      }
                    )
                  }
                  del={true}
                  onDelete={() => handleClickOpenConfirm(producto["id"])}
                />
              </div>
            );

            arrayProductos.push(aux);
          });
          setProductos(arrayProductos);
          setTotalData(res.data["total"]);
          setIsLoad(true);
        }
      })
      .catch((err) => {
        console.log(err);
        showSnack(
          "warning",
          "Se ha producido un error en la carga de productos"
        );
      });
  }

  function formProducto() {
    return (
      <>
        <GridItem xs={9} sm={9} md={9}>
          <TextField
            label="Referencia"
            defaultValue={producto_ref}
            onChange={(event) => {
              const { value } = event.target;
              setProductoRef(value);
            }}
            fullWidth={true}
            error={error && producto_ref == "" ? true : false}
          />
        </GridItem>
        <GridItem
          xs={3}
          sm={3}
          md={3}
          style={{ marginTop: "10px" }}
          align="center"
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={producto_nuevo}
                onChange={() => handleCheckNuevo()}
                value="checkedB"
                color="primary"
                classes={{
                  root: classes.radioCustom,
                  checked: classes.checked,
                }}
              />
            }
            label="Nuevo"
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={12} style={{ marginTop: "10px" }}>
          <Select2
            isMulti
            value={productosRelacionados}
            onChange={handleProductoRelacionados}
            options={listProductosOptions}
            className={
              error && productosRelacionados.value == null ? classes.error : ""
            }
            placeholder="Selecciona los productos relacionados"
            styles={{
              menu: (provided, state) => ({
                ...provided,
                zIndex: 9999,
              }),
            }}
            menuPosition={"fixed"}
          />
        </GridItem>
        <GridItem xs={4} sm={4} md={4} style={{ marginTop: "10px" }}>
          <Select2
            value={producto_grupo}
            onChange={handleGrupo}
            options={listGrupoOptions}
            className={
              error && producto_grupo.value == null ? classes.error : ""
            }
            placeholder="Selecciona grupo"
            styles={{
              menu: (provided, state) => ({
                ...provided,
                zIndex: 9999,
              }),
            }}
            menuPosition={"fixed"}
          />
        </GridItem>
        <GridItem xs={4} sm={4} md={4} style={{ marginTop: "10px" }}>
          <Select2
            isDisabled={producto_grupo.value == null ? true : false}
            value={producto_subGrupo}
            onChange={handleSubGrupo}
            options={listSubGrupoFilter}
            className={
              error && producto_subGrupo.value == null ? classes.error : ""
            }
            placeholder="Selecciona subgrupo"
            styles={{
              menu: (provided, state) => ({
                ...provided,
                zIndex: 9999,
              }),
            }}
            menuPosition={"fixed"}
          />
        </GridItem>
        <GridItem xs={4} sm={4} md={4} style={{ marginTop: "10px" }}>
          <Select2
            isDisabled={producto_subGrupo.value == null ? true : false}
            value={producto_categoria}
            onChange={handleCategoria}
            options={listCategoriaFilter}
            className={
              error && producto_categoria.value == null ? classes.error : ""
            }
            placeholder="Selecciona categoria"
            styles={{
              menu: (provided, state) => ({
                ...provided,
                zIndex: 9999,
              }),
            }}
            menuPosition={"fixed"}
          />
        </GridItem>
        <GridItem xs={6} sm={6} md={6} style={{ marginTop: "20px" }}>
          <Select2
            value={producto_visado}
            onChange={handleProductoVisado}
            options={productoVisadoOptions}
            className={
              error && producto_visado.value == null ? classes.error : ""
            }
            placeholder="Selecciona el visado"
            styles={{
              menu: (provided, state) => ({
                ...provided,
                zIndex: 9999,
              }),
            }}
            menuPosition={"fixed"}
          />
        </GridItem>
        <GridItem xs={6} sm={6} md={6} style={{ marginTop: "10px" }}>
          <TextField
            label="Renovación (Meses)"
            type="number"
            defaultValue={producto_renovacion}
            onChange={(event) => {
              const { value } = event.target;
              setProductoRenovacion(value);
            }}
            fullWidth={true}
            error={error && producto_renovacion == "" ? true : false}
          />
        </GridItem>
        <GridItem xs={6} sm={6} md={6} style={{ marginTop: "10px" }}>
          <TextField
            label="Descripción"
            defaultValue={producto_descripcion}
            onChange={(event) => {
              const { value } = event.target;
              setProductoDescripcion(value);
            }}
            multiline={true}
            rows={4}
            rowsMax={6}
            fullWidth={true}
          />
        </GridItem>
        <GridItem xs={6} sm={6} md={6} style={{ marginTop: "10px" }}>
          <TextField
            label="Indicaciones"
            defaultValue={producto_indicaciones}
            onChange={(event) => {
              const { value } = event.target;
              setProductoIndicaciones(value);
            }}
            multiline={true}
            rows={4}
            rowsMax={6}
            fullWidth={true}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={12} style={{ marginTop: "10px" }}>
          <Typography
            gutterBottom
            variant="subtitle2"
            style={{ fontWeight: "700", fontSize: "1rem" }}
          >
            Imágenes del producto
          </Typography>
          <GridItem
            xs={12}
            sm={12}
            md={12}
            style={{ margin: "10px 0px", display: "flex" }}
          >
            {Object.keys(producto_imagenes_guardadas).map((val, j) => {
              return (
                <>
                  <div
                    id={
                      "imagenCustomPreview_" +
                      producto_imagenes_guardadas[val]["id"]
                    }
                    style={{ padding: "15px 30px" }}
                  >
                    <GridItem
                      xs={3}
                      sm={3}
                      md={3}
                      style={{
                        marginTop: "10px",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <img
                        style={{
                          width: "150px",
                          height: "100px",
                          padding: "10px",
                        }}
                        src={producto_imagenes_guardadas[val]["url"]}
                      />
                      <Fab
                        size="small"
                        style={{
                          backgroundColor: PARAMS.firstColor,
                          color: "#fff",
                          fontSize: "12px",
                          textTransform: "capitalize",
                        }}
                        variant="extended"
                        onClick={() =>
                          handleDeleteImagen(
                            producto_imagenes_guardadas[val]["id"]
                          )
                        }
                      >
                        <HighlightOffIcon fontSize="small" />
                        Eliminar
                      </Fab>
                    </GridItem>
                  </div>
                </>
              );
            })}
          </GridItem>
          <Dropzone
            onLoadImage={(val) => handleImage(val)}
            initialFiles={producto_imagenes}
          />
        </GridItem>
      </>
    );
  }

  async function createProducto() {
    if (!isProcessing) {
      var validate_fields = new Map([
        ["referencia", producto_ref],
        ["descripción", producto_descripcion],
        ["renovacion", producto_renovacion],
      ]);
      var validate = validateFields(validate_fields);
      setError(false);
      if (validate.status) {
        setIsProcessing(true);
        await API.post("productos/store", {
          ref: producto_ref,
          descripcion: producto_descripcion,
          indicaciones: producto_indicaciones,
          visado: producto_visado["value"],
          renovacion: producto_renovacion,
          nuevo: producto_nuevo,
          grupo_id: producto_grupo["value"],
          subgrupo_id: producto_subGrupo["value"],
          categoria_id: producto_categoria["value"],
          relacionados: productosRelacionados,
          imagenes: producto_imagenes,
        })
          .then((res) => {
            if (res.data) {
              handleClose();
              getProductos();
              showSnack("success", "Producto creado correctamente");
            }
            setIsProcessing(false);
          })
          .catch((err) => {
            console.log(err);
            showSnack("warning", "Se ha producido un error");
            setIsProcessing(false);
          });
      } else {
        showSnack("warning", validate.message);
        setError(true);
      }
    }
  }

  function clearForm() {
    setProductoRef("");
    setProductoDescripcion("");
    setProductoVisado([]);
    setProductoRenovacion("");
    setProductoIndicaciones("");
    setProductoNuevo(false);
    setProductosRelacionados([]);
    setProductoImagenesGuardadas([]);
    setProductoImagenes([]);
    setProductoImagenesBorrar([]);
    setProductoGrupo([]);
    setProductoSubGrupo([]);
    setProductoCategoria([]);
  }

  function loadEdit(
    id,
    referencia,
    descripcion,
    indicaciones,
    renovacion,
    visado,
    nuevo,
    relacionado,
    imagenes,
    grupo,
    subgrupo,
    categoria
  ) {
    setProductoId(id);
    setProductoRef(referencia);
    setProductoDescripcion(descripcion);
    setProductoVisado([{ value: visado, label: visado }]);
    setProductoRenovacion(renovacion);
    setProductoIndicaciones(indicaciones);
    setProductoNuevo(nuevo);

    handleGrupo(getFamiliaValue(grupo), null, subgrupo.data);
    handleSubGrupo(getFamiliaValue(subgrupo), null, categoria.data);
    handleCategoria(getFamiliaValue(categoria));

    setProductosRelacionados(relacionado);
    setProductoImagenesGuardadas(imagenes);
    setProductoImagenes([]);
    setProductoImagenesBorrar([]);

    handleClickOpenEdit();
  }

  async function editProducto() {
    if (!isProcessing) {
      var validate_fields = new Map([
        ["referencia", producto_ref],
        ["descripción", producto_descripcion],
        ["renovacion", producto_renovacion],
      ]);
      var validate = validateFields(validate_fields);
      setError(false);
      if (validate.status) {
        setIsProcessing(true);
        await API.post(`productos/update/${producto_id}`, {
          ref: producto_ref,
          descripcion: producto_descripcion,
          indicaciones: producto_indicaciones,
          visado: producto_visado["value"],
          renovacion: producto_renovacion,
          nuevo: producto_nuevo,
          grupo_id: producto_grupo["value"],
          subgrupo_id: producto_subGrupo["value"],
          categoria_id: producto_categoria["value"],
          relacionados: productosRelacionados,
          imagenes: producto_imagenes,
          imagenes_borrar: producto_imagenes_borrar,
        })
          .then((res) => {
            if (res.data) {
              handleClose();
              getProductos();
              showSnack("success", "Producto editado correctamente");
            }
            setIsProcessing(false);
          })
          .catch((err) => {
            console.log(err);
            setIsProcessing(false);
          });
      } else {
        showSnack("warning", validate.message);
        setError(true);
      }
    }
  }

  async function deleteProducto() {
    if (!isProcessing) {
      setIsProcessing(true);
      await API.post(`productos/destroy/${producto_id}`)
        .then((res) => {
          if (res.data) {
            handleClose();
            getProductos();
            showSnack("success", "Producto eliminado correctamente");
            setIsProcessing(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setIsProcessing(false);
          showSnack(
            "warning",
            "Se ha producido un error, eliminando el producto"
          );
        });
    }
  }

  async function downloadExcel() {
    await API.get("productos/export", { responseType: "blob" })
      .then((res) => {
        if (res.data) {
          let blob = new Blob([res.data], { type: "application/vnd.ms-excel" });
          saveAs(blob, "Productos.xlsx");
          showSnack("success", "Descargado Excel Productos");
        }
      })
      .catch((err) => {
        console.log(err);
        showSnack("warning", "Fallo al descargar el excel");
      });
  }

  async function donwloadPlantilla() {
    await API.get("productos/plantilla", { responseType: "blob" })
      .then((res) => {
        if (res.data) {
          let blob = new Blob([res.data], {
            type: "application/vnd.ms-excel",
          });
          saveAs(blob, "plantillaProductos.xlsx");
          showSnack("success", "Plantilla descargada");
        }
      })
      .catch((err) => {
        console.log(err);
        showSnack("warning", "Fallo al descargar la plantilla");
      });
  }

  async function subirExcel(fd) {
    setIsLoading(true);
    await API.post(`productos/import`, fd, { responseType: "blob" })
      .then((res) => {
        setIsLoading(false);
        if (res.data) {
          let blob = new Blob([res.data], { type: "application/vnd.ms-excel" });
          saveAs(blob, "LogsProductos.xlsx");
          showSnack("success", "Productos importados correctamente");
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
        showSnack("warning", "Error, Al importar Productos");
      });
  }

  const classes = useStyles();

  return (
    <GridContainer>
      {/* TABLA DE PRODUCTOS */}
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="info">
            <h4 className={classes.cardTitleWhite}>Productos</h4>
            <p className={classes.cardCategoryWhite}>Gestión de productos</p>
          </CardHeader>

          <CardBody>
            {/*  */}
            <GridContainer direction="row" alignItems="flex-end">
              <GridItem xs={12} sm={12} md={12}>
                <Fab
                  style={{ backgroundColor: PARAMS.firstColor, color: "#fff" }}
                  variant="extended"
                  onClick={() => handleDonwloadExcel()}
                >
                  Descargar Excel
                </Fab>
              </GridItem>
            </GridContainer>

            {/*  */}
            <div className={classes.root}>
              <Datatable
                data={productos}
                columnNames={columnNames}
                search={(data) => handleSearch(data)}
                load={isLoad}
                loader={
                  <GridContainer
                    style={{ width: "100%", height: "300px" }}
                    direction="row"
                    alignItems="center"
                    justify="center"
                  >
                    <Loader color={PARAMS.firstColor} size={80} />
                  </GridContainer>
                }
                addItemTitle={""}
                addItemAction={() => handleClickOpen()}
                serverSide={true}
                totalRows={totalData}
                perPage={perPageData}
                handlePageChange={(page) => {
                  setActualPage(page - 1);
                }}
                handlePerRowsChange={(perPage, page) => {
                  setPerPageData(perPage);
                  setActualPage(page - 1);
                }}
              />
            </div>
          </CardBody>
        </Card>
      </GridItem>
      {/* IMPORTACION MASIVA DE PRODUCTOS */}
      <Card>
        <CardHeader color="info">
          <h4 className={classes.cardTitleWhite}>Importación de Productos</h4>
          <p className={classes.cardCategoryWhite}>
            Selecciona tu excel para importar los Productos
          </p>
        </CardHeader>

        <CardBody>
          {isLoading ? (
            <GridContainer
              style={{ width: "100%", height: "100px" }}
              direction="row"
              alignItems="center"
              justify="center"
            >
              <Loader color={PARAMS.firstColor} size={80} />
            </GridContainer>
          ) : (
            <GridContainer direction="row" alignItems="flex-end">
              <GridItem xs={12} sm={6} md={8}>
                <Fab
                  style={{
                    backgroundColor: PARAMS.firstColor,
                    color: "#fff",
                    marginBotton: 100,
                  }}
                  variant="extended"
                  onClick={() => handleDonwloadPlantilla()}
                >
                  Descargar Plantilla
                </Fab>
              </GridItem>
              <GridItem xs={12} sm={6} md={4}>
                <form onSubmit={handleSubmitExcel}>
                  <label htmlFor="btnExcel">Excel</label>
                  <input
                    type="file"
                    onChange={(event) => setExcel(event?.target?.files[0])}
                    id="btnExcel"
                  />
                  <label htmlFor="btnImages">Imagenes</label>
                  <input
                    type="file"
                    multiple
                    accept="image/png, image/jpeg, image/jpg, image/webp"
                    onChange={(event) => handleFiles(event.target.files)}
                    id="btnImages"
                  />
                  <button type="submit">SUBIR PLANTILLA</button>
                </form>
              </GridItem>
            </GridContainer>
          )}
        </CardBody>
      </Card>
      <div>
        <Modal
          open={open}
          onCancel={() => handleClose()}
          content={formProducto()}
          onConfirm={() => createProducto()}
          title="Crear producto"
          width="lg"
        />
        <Modal
          open={openEdit}
          onCancel={() => handleClose()}
          content={formProducto()}
          onConfirm={() => editProducto()}
          title="Editar producto"
          width="lg"
        />
        <Modal
          open={openConfirm}
          onCancel={() => handleClose()}
          onConfirm={() => deleteProducto()}
          confirmText="Confirmar"
          confirmIcon={
            <CheckCircleOutlineIcon
              style={{ marginRight: "10px", color: "#fff" }}
            />
          }
          title="¿Seguro que deseas borrar el producto?"
        />
      </div>
    </GridContainer>
  );
}
