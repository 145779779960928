import PeopleIcon from '@material-ui/icons/People';
import ListAltIcon from '@material-ui/icons/ListAlt';
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import FeaturedPlayListIcon from '@material-ui/icons/FeaturedPlayList';

import Dashboard from "views/dashboard.js";
import Usuarios from "views/usuarios/usuarios";
import Comerciales from "views/comerciales/comerciales";
import UserProfile from "views/usuarios/user_profile";
import Settings from "views/usuarios/settings";
import Productos from "views/productos/productos";
import Prescriptores from "views/prescriptores/prescriptores";
import Familias from "views/familias/familias";
import ActividadLog from "views/actividadLog/actividadLog";


const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: PeopleIcon,
    component: Dashboard,
    layout: "/administrador",
    visible: false
  },
  {
    path: "/usuarios",
    name: "Usuarios",
    icon: PeopleIcon,
    component: Usuarios,
    layout: "/administrador",
    visible: true
  },
  {
    path: "/productos",
    name: "Productos",
    icon: ListAltIcon,
    component: Productos,
    layout: "/administrador",
    visible: true
  },
  {
    path: "/comerciales",
    name: "Comerciales",
    icon: BusinessCenterIcon,
    component: Comerciales,
    layout: "/administrador",
    visible: true
  },
  {
    path: "/prescriptores",
    name: "Prescriptores",
    icon: RecordVoiceOverIcon,
    component: Prescriptores,
    layout: "/administrador",
    visible: true
  },
  {
    path: "/familias",
    name: "Familias",
    icon: GroupWorkIcon,
    component: Familias,
    layout: "/administrador",
    visible: true
  },
  {
    path: "/actividadLog",
    name: "Log de Actividad",
    icon: FeaturedPlayListIcon,
    component: ActividadLog,
    layout: "/administrador",
    visible: true
  },
  {
    path: "/user_profile",
    name: "Perfil Usuario",
    icon: PeopleIcon,
    component: UserProfile,
    layout: "/administrador",
    visible: false
  },
  {
    path: "/settings",
    name: "Ajustes",
    icon: PeopleIcon,
    component: Settings,
    layout: "/administrador",
    visible: false
  },
];

export default dashboardRoutes;
