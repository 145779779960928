import React, { useState, useEffect } from "react";

// components
import Table from "components/Table/TableUi";
import Modal from "components/Modal/Modal";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import API from "utils/API";
import PARAMS from "utils/PARAMS";
import { validateFields, showSnack } from 'utils/helperFunctions';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Loader from 'react-loader-spinner';
import Actions from "components/Actions/Actions";

// Core
import { makeStyles } from "@material-ui/core/styles";
import TextField from '@material-ui/core/TextField';
import Fab from '@material-ui/core/Fab';

// Icons
import AddIcon from '@material-ui/icons/Add';
import EditIcon from "@material-ui/icons/Edit";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import SearchIcon from '@material-ui/icons/Search';

export default function TableList() {
  const [comerciales, setComerciales] = useState([]);
  const [tableHead, setTableHead] = useState([
    {id: "nombre", numeric: false, disablePadding: true, label: 'Nombre'},
    {id: "email", numeric: false, disablePadding: true, label: 'Email'},
    {id: "telefono", numeric: false, disablePadding: true, label: 'Teléfono'},
    {id: "acciones", numeric: false, disablePadding: true, label: 'Acciones'},
  ]);

  function createData(nombre, email, telefono, acciones) {
    return { nombre, email, telefono, acciones };
  }

  const [isLoad, setIsLoad] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [isProcessing, setIsProcessing] = React.useState(false);
  const [toSeach, setToSearch] = React.useState(null);
  const [comercial_id, setComercialId] = React.useState('');
  const [comercial_name, setComercialName] = React.useState('');
  const [comercial_email, setComercialEmail] = React.useState('');
  const [comercial_phone, setComercialPhone] = React.useState('');

  const [open, setOpen] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [openConfirm, setOpenConfirm] = React.useState(false);
  const handleClickOpen = () => {
    clearForm();
    setOpen(true);
  };
  const handleClickOpenEdit = () => {
    setOpenEdit(true);
  };
  const handleClickOpenConfirm = (id) => {
    setOpenConfirm(true);
    setComercialId(id);
  };
  const handleClose = () => {
    setOpen(false);
    setOpenEdit(false);
    setOpenConfirm(false);
  };

  const styles = {
    cardCategoryWhite: {
      "&,& a,& a:hover,& a:focus": {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
      },
      "& a,& a:hover,& a:focus": {
        color: "#FFFFFF"
      }
    },
    cardTitleWhite: {
      color: "#FFFFFF",
      marginTop: "0px",
      minHeight: "auto",
      fontWeight: "300",
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      marginBottom: "3px",
      textDecoration: "none",
      "& small": {
        color: "#777",
        fontSize: "65%",
        fontWeight: "400",
        lineHeight: "1"
      }
    },
    root: {
      width: '100%',
    },
  };
  const useStyles = makeStyles(styles);

  useEffect(() => {
    getComerciales();
  }, []);

  async function getComerciales() {
    setIsLoad(false);
    await API.post("comerciales/index", {
      findBy: toSeach
    })
    .then(res => {
      if (res.data.length > 0) {
        const arrayComerciales = [];
        res.data.forEach(comercial => {
          let aux = createData(
            comercial["nombre"],
            comercial["email"],
            comercial['telefono'],
            (<div className={classes.root}>
              <Actions
                edit={true}
                onEdit={() => loadEdit(
                  comercial['id'],
                  comercial['nombre'],
                  comercial['email'],
                  comercial['telefono']
                )}
                del={true}
                onDelete={() => handleClickOpenConfirm(comercial['id'])}
              />
            </div>)
          );

          arrayComerciales.push(aux);
        });
        setComerciales(arrayComerciales);
        setIsLoad(true);
      }
    })
    .catch(err => {
      console.log(err);
      showSnack('warning', 'Se ha producido un error en la carga de comerciales')
    });
  }

  function formUsuarios(){
    return(<>
      <GridItem xs={6} sm={6} md={6}>
        <TextField
          label="Nombre"
          defaultValue={comercial_name}
          onChange={event => {
            const { value } = event.target;
            setComercialName(value);
          }}
          error={(error && comercial_name == '') ? true : false}
        />
      </GridItem>
      <GridItem xs={6} sm={6} md={6}>
        <TextField
          label="Email"
          defaultValue={comercial_email}
          onChange={event => {
            const { value } = event.target;
            setComercialEmail(value);
          }}
          error={(error && comercial_email == '') ? true : false}
        />
      </GridItem>
      <GridItem xs={6} sm={6} md={6} style={{ marginTop: "10px" }}>
        <TextField
          label="Teléfono"
          inputProps={{ pattern: "[a-z]" }}
          defaultValue={comercial_phone}
          onChange={event => {
            const { value } = event.target;
            setComercialPhone(value);
          }}
          inputProps={{ maxLength: 9 }}
          error={(error && comercial_phone == '') ? true : false}
        />
      </GridItem>
    </>);
  }

  async function createComercial(){
    if (!isProcessing) {
      var validate_fields = new Map([['nombre', comercial_name], ['email', comercial_email], ['teléfono', comercial_email]]);
      var validate = validateFields(validate_fields);
      setError(false);
      if (validate.status) {
        setIsProcessing(true);
        await API.post("comerciales/store", {
          nombre: comercial_name,
          email: comercial_email,
          telefono: comercial_phone
        })
        .then(res => {
          if (res.data) {
            handleClose();
            getComerciales();
            showSnack('success', 'Comercial creado correctamente');
          }
          setIsProcessing(false);
        })
        .catch(err => {
          if (err.response && err.response.data && err.response.data.error) {
            showSnack('warning', err.response.data.error);
          }else{
            showSnack('warning', 'Se ha producido un error');
          }
          setIsProcessing(false);
        });
      }else{
        showSnack('warning', validate.message);
        setError(true);
      }
    }
  };

  function clearForm(){
    setComercialId('');
    setComercialName('');
    setComercialEmail('');
    setComercialPhone('');
  }

  function loadEdit(id, nombre, email, phone){
    setComercialId(id);
    setComercialName(nombre);
    setComercialEmail(email);
    setComercialPhone(phone);
    handleClickOpenEdit();
  }

  async function editComercial(){
    if (!isProcessing) {
      var validate_fields = new Map([['nombre', comercial_name], ['email', comercial_email], ['teléfono', comercial_phone]]);
      var validate = validateFields(validate_fields);
      setError(false);
      if (validate.status) {
        setIsProcessing(true);
        await API.post(`comerciales/update/${comercial_id}`, {
          nombre: comercial_name,
          email: comercial_email,
          telefono: comercial_phone
        })
        .then(res => {
          if (res.data) {
            handleClose();
            getComerciales();
            showSnack('success', 'Comercial editado correctamente');
          }
          setIsProcessing(false);
        })
        .catch(err => {
          if (err.response.data.error) {
            showSnack('warning', err.response.data.error);
          }else{
            showSnack('warning', 'Se ha producido un error');
          }
          setIsProcessing(false);
        });
      }else{
        showSnack('warning', validate.message);
        setError(true);
      }
    }
  }

  async function deleteComercial(){
    if (!isProcessing) {
      setIsProcessing(true);
      await API.post(`comerciales/destroy/${comercial_id}`)
      .then(res => {
        if (res.data) {
          handleClose();
          getComerciales();
          showSnack('success', 'Comercial eliminado correctamente');
          setIsProcessing(false);
        }
      })
      .catch(err => {
        console.log(err);
        setIsProcessing(false);
        showSnack('warning', 'Se ha producido un error, eliminando al comercial')
      });
    }
  };

  const classes = useStyles();

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="info">
            <h4 className={classes.cardTitleWhite}>Comerciales</h4>
            <p className={classes.cardCategoryWhite}>
              Gestión de comerciales
            </p>
          </CardHeader>
          <CardBody>
            <GridContainer direction="row"  alignItems="flex-end">
            <GridItem xs={3} sm={3} md={3}>
              <Fab style={{backgroundColor: PARAMS.firstColor, color: '#fff'}} variant="extended" onClick={() => handleClickOpen()}>
                <AddIcon />
                Crear comercial
              </Fab>
            </GridItem>
              <GridItem xs={3} sm={3} md={3} >
                <TextField
                  label="Buscar"
                  defaultValue={toSeach}
                  onChange={event => {
                    const { value } = event.target;
                    setToSearch(value);
                  }}
                  onKeyPress={(event) => {
                    if (event.key === 'Enter') {
                      event.preventDefault();
                      const { value } = event.target;
                      setToSearch(value);
                      getComerciales()
                    }
                  }}
                />
                <Fab style={{backgroundColor: PARAMS.firstColor, color: '#fff'}} variant="extended" onClick={() => getComerciales()}>
                  <SearchIcon />
                </Fab>
              </GridItem>
            </GridContainer>
            <div className={classes.root}>
              {(isLoad ?
                <>
                  <Table
                    tablePaginate={true}
                    tableHead={tableHead}
                    tableData={comerciales}
                  />
                </>
              :
                <><GridContainer style={{width: '100%', height: '300px'}} direction="row"  alignItems="center" justify="center">
                    <Loader type={PARAMS.loaderType} color={PARAMS.firstColor} timeout={5000} height={100} width={100} />
                  </GridContainer>
                </>
              )}
            </div>
          </CardBody>
        </Card>
      </GridItem>
      <div>
        <Modal
          open={open}
          onCancel={() => handleClose()}
          content={formUsuarios()}
          onConfirm={() => createComercial()}
          title="Crear usuario"
          width="md"
        />
        <Modal
          open={openEdit}
          onCancel={() => handleClose()}
          content={formUsuarios()}
          onConfirm={() => editComercial()}
          title="Editar usuario"
          width="md"
        />
        <Modal
          open={openConfirm}
          onCancel={() => handleClose()}
          onConfirm={() => deleteComercial()}
          confirmText="Confirmar"
          confirmIcon={<CheckCircleOutlineIcon style={{marginRight: '10px', color: '#fff'}} />} title="¿Seguro que deseas borrar el usuario?"  />
      </div>
    </GridContainer>
  );
}
